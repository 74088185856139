@use 'src/themes/variables' as *;

.ResetPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;

  &__description {
    color: $font-color-disable;
  }

  &__Form {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 30px;

    &-title {
      color: $font-color-default;
    }

    &-description {
      color: $font-color-disable;
      margin-bottom: 10px;
    }
  }

  &__back-login {
    margin: auto;
  }
}
