.Editor {
  width: 100%;
  &__editing-area {
    display: flex;
    flex-direction: column-reverse;

    .ql-editor {
      font-family: 'Poppins-400';
      display: inline-block;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      min-width: 0;
      font-size: 14px;
      line-height: 1.5714285714285714;
      background-image: none;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      transition: all 0.2s;
      padding: 13px 10px;
      border-radius: 10px;
      min-height: 48px;
      background-color: #f4f4f4;
      color: #292d32;

      &.ql-blank::before {
        font-style: normal;
        color: #d9d9d9;
      }
    }

    .quill {
      display: flex;
      flex-direction: column-reverse;
    }

    .ql-container,
    .ql-toolbar {
      border: none !important;
    }

    .ql-toolbar {
      padding: 0 !important;
    }

    // .ql-editor:hover {
    //   border-color: #73c8f0;
    //   border-inline-end-width: 1px;
    //   z-index: 1;
    // }
  }

  &__focused {
    .ql-editor {
      background-color: #ffffff;
      border-color: #73c8f0;
      box-shadow: 0 0 0 2px rgba(5, 205, 255, 0.06);
      border-inline-end-width: 1px;
      outline: 0;
      z-index: 1;

      &.ql-blank::before {
        content: none;
      }
    }
  }

  &__hide-toolbar .ql-toolbar {
    display: none;
  }
}
