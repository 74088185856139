@use 'src/themes/variables' as *;

.Text {
  &.display1 {
    font-size: 34px;
    line-height: 50px;
    letter-spacing: -0.01em;
  }

  &.headline {
    font-family: 'Poppins-400';
    font-size: $font-size-headline;
    line-height: 36px;
  }

  &.title {
    font-family: 'Poppins-500';
    font-size: $font-size-title;
    line-height: 24px;
    letter-spacing: -0.005em;
  }

  &.subHeading {
    font-family: 'Poppins-500';
    font-size: $font-size-sub;
    line-height: 21px;
    letter-spacing: -0.005em;
  }

  &.body2 {
    line-height: 21px;
  }

  &.body1 {
    font-size: $font-size-small;
    line-height: 20px;
  }

  &.caption {
    font-size: $font-size-caption;
    line-height: 16px;
  }

  &.heading {
    font-size: $font-size-heading;
    line-height: 38px;
  }

  &.xl {
    font-size: $font-size-xl;
    line-height: 50px;
  }

  &.error {
    color: $color-error;
  }

  &.success {
    color: $color-success;
  }

  &.xl-3 {
    font-size: $font-size-3xl;
    line-height: 68px;
    letter-spacing: -0.01em;
  }

  &.default {
    font-size: $font-size-default;
    line-height: 17px;
  }
}
