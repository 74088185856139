@use 'src/themes/variables' as *;

.AddNewForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  max-width: 640px;

  &__item {
    width: 100%;

    &-reminder {
      margin-top: -18px;
    }

    &-container {
      width: 100%;

      .ant-row {
        flex-direction: column;
      }

      .ant-form-item-control {
        flex: initial;
      }

      label {
        &::after {
          display: none;
        }
      }

      &.row {
        display: flex;
        // align-items: center;
        gap: 10px;

        &.center {
          justify-content: center;
        }
      }

      .reminderAt {
        text-align: center;
      }
    }
  }

  &__questionnary {
    width: 100%;
  }

  &__text-area {
    height: 115px !important;
  }

  &__button {
    width: 173.5px;
  }
}
