.SubscriptionInfo {
  padding: 20px;

  &__top-content {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  &__current-subscription,
  &__activities {
    flex: 1;
  }

  &__current-subscription-heading,
  &__activities-heading {
    margin-bottom: 20px;
  }

  &__current-subscription {
    &-heading {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-registered {
      margin-top: 20px;
    }
  }
  &__logs {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &-title {
      margin-bottom: 20px;
    }

    &-subscription-changelog {
      flex: 1;
      width: 100%;
    }

    &-payment-log {
      flex: 1;
      width: 100%;
    }
  }
}
