@use 'src/themes/variables' as *;

.SubscriptionPaymentLogList {
  width: 100%;

  &__row {
    display: flex;
    border-bottom: 1px solid $border-default-color;
    padding: 10px;

    &.header {
      color: $color-primary;
      border: 1px solid $border-default-color;
      border-left: none;
      border-right: none;
    }

    & > :first-child {
      flex-basis: 55%;
    }
    & > :nth-child(2) {
      flex-basis: 35%;
    }
    & > :nth-child(3) {
      flex-basis: 35%;
    }
    & > :nth-child(4) {
      flex-basis: 10%;
      cursor: pointer;
    }
  }

  &__list-wrapper {
    height: 189px;
    overflow: overlay;
  }
}
