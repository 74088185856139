@use 'src/themes/variables' as *;

.Homework__content {
  height: 100%;
  padding-bottom: 20px;

  &-search {
    margin-bottom: 20px;
  }

  &-list {
    height: calc(100% - 90px);
    overflow: auto;

    &.has-border {
      padding: 6px;
      border: 1px solid $border-default-color;
      border-radius: 8px;
    }
  }
}
