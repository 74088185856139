@use 'src/themes/variables' as *;

.TimePicker {
  height: 48px;

  &.ant-picker {
    line-height: 20px;
    font-size: $font-size-small;
    background-color: $bg-input-color;
    width: 100%;

    &:focus {
      background-color: $white;
      border-color: $border-active-color;
    }
  }
  .ant-picker-input > input {
    text-align: center;
  }
}
