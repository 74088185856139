@use 'src/themes/variables' as *;

.NewQuestionnaryComponent {
  display: flex;
  gap: 8px;
  width: -webkit-fill-available;

  &__form {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-card {
      padding: 16px;
      border-radius: 8px;
      cursor: pointer;

      &__container {
        width: 100%;

        &-header {
          display: flex;
          flex-direction: row;
          gap: 10px;

          &-question {
            width: 64%;
          }

          &-questionType {
            width: 35%;
          }
        }
      }

      &__footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &-required,
        &-function {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          .FormItem {
            margin: 0;
          }
        }
      }
    }
  }

  &__controller {
    position: sticky;
    top: 100px;
    height: fit-content;
    padding: 20px;
    background-color: $bg-input-color;
    border-radius: 8px;

    .FormItem {
      cursor: pointer;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
