.EditSubscriptionModal {
  &__confirmBtn {
    margin-bottom: 10px;
  }

  &__cancelBtn {
    .Text {
      text-decoration: underline;
    }
  }

  &__addBtn {
    &.ant-btn {
      padding: 0;
      width: 20px;
      height: 20px;
      border: none;
    }

    &.plan {
      margin-top: 20px;
    }
  }

  &__list {
    &-label {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      display: flex;
      gap: 10px;
      margin-bottom: 16px;

      .FormItem {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }
}
