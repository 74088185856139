.LoginPage {
  &__container {
    &--heading {
      margin-bottom: 16px;
    }
    &--heading,
    &--endText {
      text-align: center;
    }
  }
  &__errorMsg {
    margin-bottom: 16px;
  }
}
