@use 'src/themes/variables' as *;

.TemplatesPage {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .ant-table-wrapper {
    background: $white;
    padding: 24px;
    border-radius: 8px;
  }

  .DeleteTemplateModal {
    text-align: center;
    padding: 24px 0;
  }
} 