@use 'src/themes/variables' as *;

.RevenueReport {
  .CommonContent__header {
    .CommonContent__header-title {
      font-size: 24px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 46px;
    gap: 2px;

    &-headline {
      height: 29px;
    }

    &-time {
      height: 17px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    height: auto;
  }

  &__chart {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    border: 2px solid $color-offline;
    border-radius: 8px;
    padding: 10px;

    &-title {
      align-self: start;
      margin-left: 40px;
      margin-bottom: 10px;
    }
  }

  &__right {
    width: 40%;

    &-filter {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 16px;
    }
  }

  &__filter {
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 140px;
    justify-content: space-between;
    height: 42px;
  }
}
