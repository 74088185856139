.ClientDetail {
  &__wrapper {
    .CommonContent__content {
      padding: 0;
      padding-bottom: 20px;
    }
  }

  &__title {
    .StatusBox__status {
      line-height: 15px;
    }
  }

  &__breadcrumb {
    margin-bottom: 8px;
    
    .ant-breadcrumb-link {
      color: var(--gray-600);
      cursor: pointer;
      
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}
