@use 'src/themes/variables' as *;

.FormItem {
  margin-bottom: 16px;

  .ant-form-item {
    &-label {
      padding-bottom: 4px;

      > label {
        font-size: $font-size-caption;
        line-height: 16px;
        height: auto;

        width: 100%;
        display: flex;
        justify-content: space-between;

        .rightLabel {
          color: $font-color-disable;
        }
      }
    }

    &-explain-error {
      text-align: left;
      font-size: $font-size-caption;
      line-height: 1.25;
    }
  }
}
