.QuoteActionModal {
  &__quote-textarea {
    resize: none !important;
    height: 115px !important;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 16px;

    &.delete {
      flex-direction: row;

      :first-child {
        order: 1;
      }
    }
  }

  &__button {
    width: 100%;
  }
}
