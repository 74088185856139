// Light Green - Primary Color Pallete
$light-green00: #62950b !default;
$light-green01: #85c021 !default;
$light-green02: #93d721 !default;
$light-green03: #b1ef48 !default;
$light-green04: #cbff72 !default;
$light-green05: #e8ffc1 !default;
$light-green06: #f4ffe2 !default;

// Grayscale - Neutral Color Pallete
$grayscale90: #242424 !default;
$grayscale80: #424242 !default;
$grayscale70: #636363 !default;
$grayscale60: #808080 !default;
$grayscale50: #a0a0a0 !default;
$grayscale40: #bebebe !default;
$grayscale40: #d3d3d3 !default;
$grayscale30: #d3d3d3 !default;
$grayscale20: #ececec !default;
$grayscale10: #f5f5f5 !default;
$white: #ffffff !default;
$black: #000000 !default;
$philippine-gray: #8a8a8a !default;

// Dark Green - Secondary Color Pallete
$dark-green01: #006832 !default;
$dark-green02: #148148 !default;
$dark-green03: #46ae78 !default;
$dark-green04: #6fd6a0 !default;
$dark-green05: #8ceebb !default;
$dark-green06: #b3fcd6 !default;
$dark-green07: #e0ffef !default;

// Semantic Color
$color-information: #1890ff !default;
$color-success: #35d6af !default;
$color-warning: #ffac30 !default;
$color-error: #ff7777 !default;
$color-primary: #48abe2;
$color-purple: #a25aff;
$bg-gray: #eff2fc !default;
$bg-success: #35d6af !default;
$bg-error: #ff7777 !default;
$bg-gray: #eff2fc !default;
$bg-gray-2: #fcfcfc;

// Font color
$font-color-default: #292d32;
$font-color-disable: #8a8a8a;

// Border color
$border-default-color: #e1e6ef;
$border-active-color: #19bcfe;

// Bg color
$bg-input-color: #f4f4f4;
$bg-base: #eff2fc;
$bg-homework-task: #f6f7f9;

// homework color
$activity-color: #ffac30;
$question-color: #a25aff;
$written-color: #35d6af;
$video-color: #ff7777;
$message-color: #5a73d8;
$color-offline: #e1e6ef;
