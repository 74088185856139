@use 'src/themes/variables' as *;

.ProfilePage {
  &__wrapper {
    .CommonContent__content {
      padding: 20px 20px 30px;
    }

    .ProfilePageForm {
      margin-top: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid $border-default-color;
      &__row {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .FormItem {
          width: 100%;
        }
      }

      &__footer {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 16px;
        margin-bottom: 16px;

        .Button {
          width: 160px;
        }
      }
    }
  }
}
