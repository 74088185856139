.AddAndEditPsychoeducation {
  &__header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  &__Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 0 auto;
    max-width: 640px;

    &-item-container {
      width: 100%;

      .ant-row {
        flex-direction: column;
      }

      .ant-form-item-control {
        flex: initial;
      }

      label {
        &::after {
          display: none;
        }
      }

      &.row {
        display: flex;
        align-items: center;
        gap: 10px;

        &.center {
          justify-content: center;
        }
      }
    }

    &-text-area {
      height: 115px !important;
    }

    &-button {
      width: 173.5px;
    }

    &-quill-editor {
      flex-direction: column;
      width: 100%;
      height: 200px;
    }
  }
  .ql-toolbar.ql-snow .ql-preview {
    img {
      display: block;
    }
  }
}
