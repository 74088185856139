@use 'src/themes/variables' as *;

.ant-select-item-option-content {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.color-picker-container {
  .color-preview {
    cursor: pointer;
    padding: 6px 12px;
    border: 1px solid $border-default-color;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 32px;

    .color-value {
      margin-left: 8px;
      color: $font-color-default;
      font-size: $font-size-small;
    }
  }
}

.preset-colors {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  margin-top: 16px;
  padding: 8px;
  border-top: 1px solid $border-default-color;

  .preset-color {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.ant-space {
  svg {
    width: 20px;
    height: 20px;
  }
} 