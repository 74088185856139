@use 'src/themes/variables' as *;

.PsychoeducationDetail {
  height: calc(100% - 40px);
  overflow: auto;

  img {
    width: 100%;
  }

  &__video {
    color: $font-color-default;

    &--title {
      margin-bottom: 8px;
    }

    &--content {
      margin-bottom: 21px;
    }

    &--player {
      padding: 0 30px;
      overflow: hidden;

      iframe {
        border-radius: 8px;
      }
    }
  }
}
