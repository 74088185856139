@use 'src/themes/variables' as *;

.GeneralInformation {
  padding: 20px;
  &__section {
    margin-bottom: 16px;

    &-header {
      margin-bottom: 16px;
    }

    &-details {
      padding: 0 32px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__info {
    font-size: $font-size-sub;
    display: flex;

    :first-child {
      flex: 0 0 200px;
      color: $font-color-disable;
    }
  }
}
