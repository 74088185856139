@use 'src/themes/variables' as *;

.AddHomeworkLayout {
  min-height: 100%;
  height: auto;
  border-radius: 8px;

  .AddHomeworkLayoutHeader {
    background-color: transparent;
    padding: 0;

    &__button {
      display: flex;
      gap: 8px;
      height: 40px;
    }
  }

  .Content {
    padding: 20px;
    border-radius: 8px;
    background-color: $white;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &-left {
        display: flex;
        align-items: center;
        gap: 25px;
      }

      &-back {
        cursor: pointer;
      }

      &-delete {
        width: 100px;
      }
    }
  }
}
