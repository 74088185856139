@use './variables' as *;
@import './antd-override';
@import './animate';

@font-face {
  font-family: 'Poppins-300';
  src: url('../assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-400';
  src: url('../assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-500';
  src: url('../assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins-600';
  src: url('../assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-700';
  src: url('../assets/fonts/Poppins-Bold.ttf');
}

/* Global - Style */
body {
  font-size: $font-size-default;
  height: auto;
  min-height: 100vh;
}

div {
  &#root,
  &.App {
    height: 100%;
    min-height: 100vh;
  }
}

/* H1 - Typography */
h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: $font-color-default;
}

/* H2 - Typography */
h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: $font-color-default;
}

/* H3 - Typography */
h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  color: $font-color-default;
}

.hasRequireMark {
  position: relative;
  max-width: max-content;
  &::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff7777;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
    position: absolute;
    right: -12px;
    top: -1px;
  }
}
