@use 'src/themes/variables' as *;

.PsychoeducationPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;

  &__button {
    display: flex;
    gap: 8px;
  }

  &__dropdown-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 0;
  }
}

.PsychoeducationPage {
  background-color: $white;
  height: calc(100vh - 170px);
  border-radius: 8px;

  &.is-add-and-edit-page {
    height: auto;
  }

  .Sider {
    padding: 18px 16px 28px 16px;
    border-right: 1px solid $border-default-color;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow-y: auto;

    &,
    .ant-menu {
      background-color: $white !important;
    }

    .ant-menu {
      border: none;
    }

    & > .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__menu {
      .ant-menu-item,
      .ant-menu-submenu-title {
        font-family: 'Poppins-400';
        font-size: $font-size-small;
        line-height: 20px;
        background-color: $white;
        color: $font-color-disable;
        height: 40px;
        padding: 15px 18px !important;
        border: 0;
      }

      .new-topic {
        background-color: $color-primary;
        color: $white;
        border: none;
        width: 100%;
      }

      .ant-menu-item-selected {
        background-color: $white;
        color: $color-information;
      }
    }
  }

  .Content {
    background-color: $white;
    padding: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &__header {
      color: $font-color-default;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0 0 16px 10px;
    }

    &__search {
      margin-bottom: 10px;
    }

    &__list {
      height: calc(100% - 58px);
    }

    &__spin-loading {
      display: flex;
      justify-content: center;
    }
  }

  &__AddNewTopicModal,
  &__DeletePsychoeducationModal {
    .AddNewTopicModal__buttons,
    .DeletePsychoeducationModal__buttons {
      display: flex;
      gap: 16px;
    }

    .AddNewTopicModal__buttons {
      margin-top: 32px;
    }

    .AddNewTopicModal__button,
    .DeletePsychoeducationModal__button {
      width: 100%;
    }
  }

  .Table__wrapper {
    height: calc(100% - 40px);
  }
}
