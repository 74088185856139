@use 'src/themes/variables' as *;

.RevenueDetailList {
  width: 100%;
  .content-item {
    flex: 1;
    align-items: center;
  }
  &__row {
    display: flex;
    border-bottom: 1px solid $border-default-color;
    padding: 10px;
    height: 38px;

    &.header {
      color: $color-primary;
      border: 1px solid $border-default-color;
      border-left: none;
      border-right: none;

      .content-row {
        flex: 1;
        align-items: center;
      }
    }

    &.content {
      .content-item {
        flex: 1;
        align-items: center;
      }
    }

    & > :first-child {
      flex-basis: 55%;
    }
    & > :nth-child(2) {
      flex-basis: 35%;
    }
    & > :nth-child(3) {
      flex-basis: 10%;
      cursor: pointer;
    }
  }

  &__list-wrapper {
  }
}
