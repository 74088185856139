@use 'src/themes/variables' as *;

.PreviewModal {
  &__title {
    border-bottom: 1px solid $border-default-color;
    padding-bottom: 10px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 173.5px;
  }

  img {
    width: 100%;
  }

  .ql-editor {
    padding: 0;
    height: 50vh;
    margin: 10px 0 20px 0;
  }
}
