@use 'src/themes/variables' as *;

.SubscriptionPricingCard {
  padding: 20px 24px;
  border: 2px solid $border-default-color;
  border-radius: 8px;
  width: 370px;
  color: #1a1a1a;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:hover {
    border-color: $border-active-color;
  }

  &__top {
    flex: 1;
  }

  &__price {
    font-size: 48px;
    margin: 10px 0;

    &.Text {
      line-height: 58px;
    }
  }

  &__descriptions {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__description {
    display: flex;
    align-items: center;
    gap: 6px;

    &-checkIcon {
      color: $color-success;
    }
  }

  &__selectPlan {
    width: 100%;
    margin-top: 6px;

    .ant-select-selection-item {
      height: 38px;
    }

    &-popup {
      margin-bottom: 50px;

      .ant-select-item-option {
        padding: 10px;

        &:not(:first-child) {
          margin-top: 6px;
        }
      }
    }
  }

  &__editBtn {
    margin-top: 10px;
  }
}
