.Clients {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__tabs {
    margin-bottom: 20px;
  }
}
