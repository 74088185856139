.ChangePasswordModal {
  &__title {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 16px;
    color: #8a8a8a;
  }

  &__input-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__buttons {
    margin-top: 4px;
    display: flex;
    gap: 10px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;

    &__errorMsg {
      margin-bottom: 16px;
    }
  }

  .ant-modal-content {
    padding: 30px 20px !important;
  }
}
