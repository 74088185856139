.practitioners-table {
  padding: 24px;

  .responsive-table {
    // Add shadow to table
    .ant-table {
      background: white;
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
                  0 1px 6px -1px rgba(0, 0, 0, 0.02),
                  0 2px 4px 0 rgba(0, 0, 0, 0.02);
    }

    // Header styling
    .ant-table-thead > tr > th {
      background: #fafafa;
      font-weight: 600;
      
      &::before {
        display: none; // Remove the vertical line in header
      }
    }

    // Row hover effect
    .ant-table-tbody > tr:hover > td {
      background: #fafafa;
    }

    // Status box alignment
    .StatusBox__status {
      display: inline-flex;
      align-items: center;
      height: 24px;
      padding: 0 12px;
      border-radius: 12px;
      font-size: 12px;
    }

    // Practice name styling
    .practice-name {
      font-size: 12px;
    }

    // Responsive adjustments
    @media (max-width: 768px) {
      .ant-table {
        border-radius: 0;
      }

      // Stack content vertically on mobile
      .ant-table-tbody > tr > td {
        padding: 12px 8px;
      }
    }

    // Pagination styling
    .ant-pagination {
      margin: 16px 0;
      padding: 0 16px;
    }
  }
} 