@use 'src/themes/variables' as *;

.SubscriptionCard {
  display: flex;
  gap: 16px;

  &__wrapper {
    width: 100%;
    max-width: 370px;
    border-radius: 10px;

    &.active {
      border-top: 5px solid $color-success;
      box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
    }

    .ant-card-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__package-name {
    color: $color-success;
  }

  &__package-price {
    display: flex;
    gap: 8px;

    .currency {
      margin: 7px 0 -7px;
    }
  }
}
