.AuthLayout {
  height: 100%;
  min-height: 100vh;
  &__content {
    margin-top: 64px;
    margin-left: 340px;
    padding: 16px 32px;
    height: calc(100% - 150px);
  }
}
