@use 'src/themes/variables' as *;

.PsychoeducationTable {
  height: calc(100% - 40px);

  &.Table.ant-table-wrapper .ant-table-thead .ant-table-cell {
    font-family: 'Poppins-400';
    font-weight: 500;
  }

  &.Table.ant-table-wrapper .ant-table-cell {
    padding: 9px 16px;
  }

  .ant-table {
    font-size: $font-size-default !important;
  }

  &__row {
    &-modified {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      & > * {
        cursor: pointer;
      }
    }
  }
}
