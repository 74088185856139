@use 'src/themes/variables' as *;

.DetailedStatistic {
  display: flex;
  flex-wrap: wrap;

  &__practitioners,
  &__clients {
    flex: 1;
    border-right: 1px solid $border-default-color;
  }
}
