@use 'src/themes/variables' as *;

.Input {
  padding: 13px 10px;
  border-radius: 10px;
  height: 48px;

  &.ant-input {
    line-height: 20px;
    font-size: $font-size-small;
    background-color: $bg-input-color;

    &:focus {
      background-color: $white;
      border-color: $border-active-color;
    }
  }

  &.wrapper {
    &.ant-input-affix-wrapper {
      background-color: $bg-input-color;

      > input.ant-input {
        background-color: $bg-input-color;

        &:focus {
          background-color: $white;
        }
      }

      &:focus-within {
        background-color: $white;
      }
    }
  }
}
