@use 'src/themes/variables' as *;

.StatisticCard {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__wrapper {
    background-color: $bg-gray;
    height: 112px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__decor {
    width: 4px;
    height: 20px;
    border-radius: 10px;

    &--discharged {
      background-color: $color-error;
    }
    &--active {
      background-color: $border-active-color;
    }
    &--pending {
      background-color: $activity-color;
    }

    &--message {
      background-color: $message-color;
    }
  }

  &__heading {
    color: $font-color-default;
  }

  &__content {
    &--discharged {
      color: $color-error;
    }
    &--active {
      color: $border-active-color;
    }
    &--pending {
      color: $activity-color;
    }
    &--message {
      color: $message-color;
    }
  }
}
