@use 'src/themes/variables' as *;

.Overview {
  margin-bottom: 32px;

  .CommonContent__header {
    height: 29px;
    .CommonContent__header-title {
      font-size: 24px;
    }
  }

  &__filter {
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border: 2px solid;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 140px;
    justify-content: space-between;
    height: 42px;
  }

  &__container {
    width: '100%';
    display: flex;
    flex-direction: row;
    gap: 20px;
    &-card {
      flex: 1;
      &-item {
        background-color: $bg-gray;
        height: 112px;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
