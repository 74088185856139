@use 'src/themes/variables' as *;

.HomeworkHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;

  &__button {
    display: flex;
    gap: 8px;
    height: 40px;
  }
}

.HomeworkLayout {
  height: calc(100vh - 170px);
  border-radius: 8px;

  .Sider {
    padding: 18px 16px 28px 16px;
    border-right: 1px solid $border-default-color;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow-y: auto;

    &,
    .ant-menu {
      background-color: $white !important;
    }

    .ant-menu {
      border: none;
    }

    & > .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__menu {
      .ant-menu-item,
      .ant-menu-submenu-title {
        font-family: 'Poppins-400';
        font-size: $font-size-small;
        line-height: 20px;
        background-color: $white;
        color: $font-color-disable;
        height: 40px;
        padding: 15px 18px !important;
        border: 0;
      }

      .new-topic {
        background-color: $color-primary;
        color: $white;
        border: none;
        width: 100%;
      }

      & > .ant-menu-item,
      .ant-menu-submenu-title {
        font-family: 'Poppins-500';
        font-size: 14px;

        &::before {
          content: '';
          width: 4px;
          height: 20px;
          border-radius: 10px;
          background-color: $border-default-color;
          position: absolute;
          left: 0;
        }
      }

      .ant-menu-submenu-selected > .ant-menu-submenu-title {
        &::before {
          background-color: $color-warning;
        }
      }

      .ant-menu-submenu-selected:last-of-type > .ant-menu-submenu-title {
        &::before {
          background-color: $color-error;
        }
      }

      & > .ant-menu-item-selected:nth-child(2) {
        &::before {
          background-color: $color-purple;
        }
      }

      & > .ant-menu-item-selected:nth-child(3) {
        &::before {
          background-color: $color-success;
        }
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-selected > .ant-menu-submenu-title {
        background-color: $white;
        color: $color-information;
      }
    }
  }

  .Content {
    background-color: $white;
    padding: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &__header {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &-editIcon {
        cursor: pointer;
      }

      &-delete {
        width: 100px;
      }
    }
  }

  &__AddNewTopicModal {
    .AddNewTopicModal__buttons {
      display: flex;
      gap: 16px;
      margin-top: 32px;
    }

    .AddNewTopicModal__button {
      width: 100%;
    }
  }
}
