@use 'src/themes/variables' as *;

.HomeworkTask {
  background-color: $bg-homework-task;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: 12px 16px;
  border-left: 4px solid;
  border-radius: 6px;

  &.Activity {
    border-left-color: $color-warning;
  }
  &.Questionnaire {
    border-left-color: $color-purple;
  }
  &.WrittenTask {
    border-left-color: $color-success;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    & > * {
      cursor: pointer;
    }
  }
}
