@use 'src/themes/variables' as *;

.ConfirmModal {
  width: 370px !important;

  .ant-modal-content {
    padding: 0 !important;
  }
  .icon {
    display: flex;
    justify-content: center;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 20px 30px 20px;
  }

  .heading {
    text-align: center;
    margin: 12px 0;
  }
}
