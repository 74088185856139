.PricingPlanPage {
  &__header {
    margin-bottom: 8px;
  }

  &__content {
    width: fit-content;
    margin: 0 auto;
  }

  &__packages {
    display: flex;
    gap: 10px;
  }

  &__spin-loading {
    display: flex;
    justify-content: center;
  }
}
