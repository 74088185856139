@use 'src/themes/variables' as *;

.ForgotPasswordPage {
  &__errorMsg {
    margin-bottom: 16px;
  }

  &__description {
    color: $font-color-disable;
  }

  &__Form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__input-email-login {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: auto;
  }

  &__login {
    color: $color-primary;
    cursor: pointer;
    width: max-content;
  }
}
