.IndividualClientDetail {
  &__wrapper {
    .CommonContent__content {
      padding: 0;
      padding-bottom: 20px;
    }
  }

  &__title {
    .StatusBox__status {
      line-height: 15px;
    }
  }

  &__breadcrumb {
    margin-bottom: 8px;
    
    .ant-breadcrumb-link {
      color: var(--gray-600);
      cursor: pointer;
      
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

.GeneralInformation {
  .error-alert {
    margin: 0 24px 16px;
  }

  .info-section {
    padding: 24px;
    
    .info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    .edit-button {
      padding: 4px 0;
      height: auto;
    }

    .info-grid {
      margin-top: 8px;
    }

    .info-item {
      margin-bottom: 16px;
      
      .label {
        color: var(--gray-600);
        margin-bottom: 4px;
        display: block;
      }
    }

    .edit-form {
      .ant-form-item {
        margin-bottom: 24px;
        
        &:last-child {
          margin-bottom: 0;
        }

        .ant-form-item-extra {
          font-size: 12px;
          color: var(--gray-600);
          margin-top: 4px;
        }

        &-with-help {
          margin-bottom: 24px;
        }

        &-has-feedback {
          .ant-input {
            padding-right: 32px;
          }

          .ant-form-item-children-icon {
            right: 12px;
          }
        }

        &-has-success {
          .ant-input {
            border-color: var(--success-color);
            
            &:hover {
              border-color: var(--success-color);
            }
            
            &:focus {
              border-color: var(--success-color);
              box-shadow: 0 0 0 2px rgba(var(--success-rgb), 0.2);
            }
          }
        }

        &-has-error {
          .ant-input {
            border-color: var(--error-color);
            
            &:hover {
              border-color: var(--error-color);
            }
            
            &:focus {
              border-color: var(--error-color);
              box-shadow: 0 0 0 2px rgba(var(--error-rgb), 0.2);
            }
          }
        }

        &-has-warning {
          .ant-input {
            border-color: var(--warning-color);
            
            &:hover {
              border-color: var(--warning-color);
            }
            
            &:focus {
              border-color: var(--warning-color);
              box-shadow: 0 0 0 2px rgba(var(--warning-rgb), 0.2);
            }
          }
        }
      }

      .ant-input {
        padding: 8px 12px;

        &:hover {
          border-color: var(--primary-color);
        }
        
        &:focus {
          border-color: var(--primary-color);
          box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
        }
      }

      .ant-form-item-explain {
        font-size: 12px;
        min-height: 20px;
        padding-top: 2px;
      }
    }

    .inline-edit-form {
      .ant-form-item-control-input {
        min-height: unset;
      }

      .ant-form-item-explain {
        font-size: 12px;
        min-height: 20px;
        padding-top: 2px;
      }
    }
  }
} 