@use 'src/themes/variables' as *;

.Button {
  font-family: 'Poppins-500';
  height: 48px;

  &.ant-btn {
    height: auto;
    line-height: 21px;
    border-radius: 10px;
    padding-top: 12.5px;
    padding-bottom: 12.5px;

    // size
    &-sm {
      padding-top: 8.5px;
      padding-bottom: 8.5px;
    }

    &-lg {
      padding-top: 15.5px;
      padding-bottom: 15.5px;
      font-size: $font-size-default;
    }

    // type
    &-default {
      border-color: $border-default-color;
    }

    &-link {
      color: $color-primary;
    }

    &-primary {
      &:disabled {
        border-style: none;
      }
    }
  }
}
