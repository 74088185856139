@use 'src/themes/variables' as *;

.Select {
  font-family: 'Poppins-500';
  width: '100%';
  font-size: 13px;
  .ant-select-selector {
    height: 48px !important;
    align-items: center;
    background-color: #f4f4f4 !important;
  }

  .ant-select-selection-item {
    font-size: 13px !important;
  }

  .ant-select-item-option {
    font-size: 13px !important;
  }

  .ant-select-item {
    height: 48px !important;
    align-items: center;
    font-size: 13px !important;
  }
}
