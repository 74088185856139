@use 'src/themes/variables' as *;

.Home {
  &__header {
    height: 168px;
    margin-bottom: 32px;

    &-loading {
      display: 'flex';
      justify-content: 'center';
      height: 42px;
    }
  }
}
